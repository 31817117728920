import React, { Component } from "react"
import facebookImg from "../images/facebook.png"
import linkedInImg from "../images/linkedin.png"
import instagramImg from "../images/instagram.png"
import phonIcon from "../images/phone-icon.png"
import emailIcon from "../images/email-icon.png"
import twitterImg from "../images/twitter.png"
import locatorIcon from "../images/locatoricon.png"
import { Link } from "gatsby"

class Footer extends Component {
  constructor() {
    super()
    this.state = {
      feedback: "",
      isShowSuccessMessage: false,
      isShowErrorMessage: false,
    }
  }

  handleSubmitFeedback = e => {
    e.preventDefault()
    const { feedback } = this.state
    if (feedback) {
      this.setState({
        isShowSuccessMessage: true,
        isShowErrorMessage: false,
        feedback: "",
      })
    } else {
      this.setState({ isShowSuccessMessage: false, isShowErrorMessage: true })
    }
  }

  handleChangeInputFeedback = event => {
    this.setState({ feedback: event.target.value })
  }
  render() {
    const { feedback, isShowErrorMessage, isShowSuccessMessage } = this.state
    return (
      <footer className="wrapper">
        <div className="wrapper footer_links">
          <div className="container">
            <div className="col_left">
              {/* <div className="inner_wrap">
                <p className="wrapper">
                  Please share your latest updates on my email:
                </p>
                <form className="wrapper">
                  <input
                    type="text"
                    name="feedback"
                    onChange={this.handleChangeInputFeedback}
                    value={feedback}
                  />
                  <a onClick={this.handleSubmitFeedback}>
                    <span className="btn2">Submit</span>
                  </a>
                  {isShowSuccessMessage && (
                    <span style={{ color: "green" }}>
                      Your feedback has been submmited successfully.
                    </span>
                  )}
                  <span
                    className="wrapper errormsg"
                    style={{
                      visibility:
                        isShowErrorMessage && !feedback ? "visible" : "hidden",
                    }}
                  >
                    Please enter your feedback
                  </span>
                </form>
                <ul className="wrapper social_links">
                  <li>
                    <a href="javascript:void(0)">
                      <span>
                        <img src={facebookImg} alt="" />
                      </span>
                    </a>
                  </li>

                  <li>
                    <a href="javascript:void(0)">
                      <span>
                        <img src={linkedInImg} alt="" />
                      </span>
                    </a>
                  </li>

                  <li>
                    <a href="javascript:void(0)">
                      <span>
                        <img src={twitterImg} alt="" />
                      </span>
                    </a>
                  </li>

                  <li>
                    <a href="javascript:void(0)">
                      <span>
                        <img src={instagramImg} alt="" />
                      </span>
                    </a>
                  </li>
                </ul>
              
              </div>
             */}
            </div>

            <div className="col_right">
              <ul className="wrapper footer_link">
              
                {/* <li>
                  <a href="javascript:void(0)">
                    <span>About</span>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <span>Portfolio</span>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <span>Technologies</span>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <span>Blogs</span>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <span>Contact</span>
                  </a>
                </li> */}
              </ul>

              {/* <ul className="wrapper footer_link services_link">
                <li>
                  <a href="javascript:void(0)">
                    <span>Services</span>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <span>Web App Developers</span>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <span>Mobile App Developers</span>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <span>Digital Product Design</span>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)">
                    <span>IT Staff Augmentation</span>
                  </a>
                </li>
              </ul> */}

              <span className="wrapper get_in_touch" style={{ marginBottom: '16px' }}>Get In Touch</span>

              <ul className="wrapper footer_link get_in_touch_link">
                <li>
                  <a href="tel:9978618171">
                    <span>
                      <img src={phonIcon} alt="" /> +91 9978618171
                    </span>
                  </a>
                </li>
                <li>
                  <a href="mailto:hello@albetrios.com">
                    <span>
                      <img src={emailIcon} alt="" /> hello@albetrios.com
                    </span>
                  </a>
                </li>
              </ul>
              <p className="wrapper address">
                <img src={locatorIcon} alt="" /> 406, Avadh viceroy, Sarthana
                Jakatnaka
              </p>
            </div>
          </div>
        </div>

        <div className="wrapper copyright_text">
          <div className="container">
            <p> &copy; Copyright 2024 Albetrios. All rights reserved</p>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
