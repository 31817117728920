import React from "react"
import global from "../styles/global.scss"
import headerfooter from "../styles/header-footer.scss"
import index from "../styles/index.scss"

export default function head() {
  return (
    <head>
      <meta charSet="UTF-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
      />
      <title>Albetrios</title>
      <link href={global} rel="stylesheet" type="text/css" />
      <link href={headerfooter} rel="stylesheet" type="text/css" />
      <link href={index} rel="stylesheet" type="text/css" />
    </head>
  )
}
