import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Head from "./head"
import logo from "../images/logo.png"
import phonIcon from "../images/phone-icon.png"
import emailIcon from "../images/email-icon.png"
// import AniLink from "gatsby-plugin-transition-link/AniLink"
// import TransitionLink from "gatsby-plugin-transition-link"
const Header = ({ siteTitle }) => {
  // function hamburgerIcon() {
  //   var hbIcon = document.getElementById("hamburgerIcon")
  //   var showMenu = document.getElementById("showMenu")
  //   hbIcon.classList.toggle("animate")
  //   showMenu.classList.toggle("openMenu")
  // }
  const handleOpenMenu = () => {}
  return (
    <React.Fragment>
      <Head />

      <header className="wrapper">
        <div className="container">
          <a href="javascript:void(0)">
            <Link to="/">
              {" "}
              <span className="logoImg">
                <img src={logo} alt="" />
              </span>
            </Link>
          </a>

          <nav>
            {/* <ul>
              <li>
                <a href="javascript:void(0)">
                  <span>Who we are</span>
                </a>
              </li>

              <li>
                <a href="javascript:void(0)">
                  <span>what we do</span>
                </a>
              </li>

              <li>
                <a href="javascript:void(0)">
                  <span>our portfolio</span>
                </a>
              </li>
            </ul> */}

            <ul className="headercontact">
              <li>
                <a href="tel:9978618171">
                  <span>
                    <img src={phonIcon} alt="" /> +91 9978618171
                  </span>
                </a>
              </li>
              <li>
                <a href="mailto:hello@albetrios.com">
                  <span>
                    <img src={emailIcon} alt="" /> hello@albetrios.com
                  </span>
                </a>
              </li>
            </ul>
          </nav>

          {/* <AniLink swipe direction="right" to="/contact-us" duration={1}>
            <span className="btn1">Let's talk</span>{" "}
          </AniLink> */}
          <a href="javascript:void(0)">
            <Link to="/contact-us">
              <span className="btn1" style={{ right: '5%' }}>Let's talk</span>{" "}
            </Link>
          </a>

          {/* <div className="nav-toggle" onClick={handleOpenMenu}>
            <span></span>
            <span></span>
            <span></span>
          </div> */}

          {/* // Menu icon */}
          {/* <span className="menu-wrapper">
            <abbr id="hamburgerIcon" className="hamburger-menu"></abbr>
          </span> */}
        </div>
      </header>
    </React.Fragment>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

// <header className="wrapper">
// <div className="container">
//   <a>
//     <Link to="/">
//       {" "}
//       <span className="logoImg">
//         <img src={logo} alt="" />
//       </span>
//     </Link>
//   </a>

//   <nav>
//     <ul>
//       <li>
//         <a href="javascript:void(0)">
//           <span>Who we are</span>
//         </a>
//       </li>

//       <li>
//         <a href="javascript:void(0)">
//           <span>what we do</span>
//         </a>
//       </li>

//       <li>
//         <a href="javascript:void(0)">
//           <span>our portfolio</span>
//         </a>
//       </li>
//     </ul>
//   </nav>

//   <a>
//     <Link to="/contact-us">
//       <span className="btn1">Let's talk</span>
//     </Link>
//   </a>

//   <div className="nav-toggle">
//     <span></span>
//     <span></span>
//     <span></span>
//   </div>
//   {/* onclick={hamburgerIcon()} */}
//   <span className="menu-wrapper">
//     <abbr id="hamburgerIcon" className="hamburger-menu"></abbr>
//   </span>
// </div>
// </header>
